import {
    map,
    pathOr
} from 'ramda';
import LocaleKeys from '../../locales/keys';
import {
    i18n,
    InjectorHelper,
    FeatureToggleConstants
} from 'invision-core';
import {
    INVOICE_ITEM_TYPES,
    NA
} from '../constants/billing.payments.constants';
import {
    DISPUTE_INVOICE,
    PAYMENT_ADJUSTMENT_ACCESS
} from '../../security.attributes';
import {RIGHT_ALIGNED_HEADER_TEMPLATE} from 'invision-ui/lib/components/collections/datatable/cellTemplates/cellTemplates';
import {hasAccess} from 'invision-core/src/components/security/permission.service';

const formatChargeDate = (periodStart, periodEnd, view) => {
    const $filter = InjectorHelper.getFilterService();

    const prorate = view.Prorate ? i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.PRORATED) : '';

    if (periodStart && periodEnd) {
        if (periodStart === periodEnd) {
            return i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.PERIOD_FORMAT_SINGLE, {
                additional_info: prorate,
                periodStart: $filter('localShortDate')(periodStart)
            });
        } else {
            return i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.PERIOD_FORMAT, {
                additional_info: prorate,
                periodStart: $filter('localShortDate')(periodStart),
                periodEnd: $filter('localShortDate')(periodEnd)
            });
        }
    }
    return null;
};

export function createInvoiceItemsTableViewModel(
    invoiceItems,
    invoiceSummary,
    invoiceItemTypes,
    canApplyAdjustment,
    discountsMetadataMap = {},
    userSecurityAttributes,
    witheldTaxesApplicable = false,
    currentCustomer,
    features = {}) {

    const tableData = {
        columnDefs: [
            {
                field: 'subscriberItem',
                displayName: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.SUBSCRIBER_ITEM),
                cellTemplate: require('./../../components/billingPayments/cellTemplates/item.template.html'),
                width: '*',
                minWidth: 150,
                resizable: true,
                enableSorting: true,
                enableColumnMoving: false
            }, {
                field: 'serviceType',
                displayName: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.SERVICE_TYPE),
                width: '*',
                minWidth: 75,
                resizable: true,
                enableSorting: true,
                enableColumnMoving: false
            }, {
                field: 'serviceName',
                displayName: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.SERVICE_NAME),
                width: '*',
                minWidth: 70,
                resizable: true,
                enableSorting: true,
                enableColumnMoving: false,
                cellTemplate: require('./../../components/billingPayments/cellTemplates/service.name.html')
            }, {
                field: 'chargePeriod',
                displayName: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.CHARGE_PERIOD),
                cellTemplate: require('./../../components/billingPayments/cellTemplates/charge.period.html'),
                width: '*',
                minWidth: 180,
                resizable: true,
                enableSorting: true,
                enableColumnMoving: false
            }, {
                field: 'originalCharge',
                displayName: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.ORIGINAL_CHARGE),
                cellTemplate: require('./../../components/billingPayments/cellTemplates/original.charge.template.html'),
                headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE,
                width: '150',
                cellClass: 'c-dataType-number',
                minWidth: 70,
                resizable: true,
                enableSorting: true,
                enableColumnMoving: false
            }, {
                field: 'discount',
                displayName: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.DISCOUNT),
                cellTemplate: require('./../../components/billingPayments/cellTemplates/discount.template.html'),
                headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE,
                width: '125',
                cellClass: 'c-dataType-number',
                minWidth: 70,
                resizable: true,
                enableSorting: true,
                enableColumnMoving: false
            }, ...(witheldTaxesApplicable ? [{
                field: 'withheldTaxes',
                headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE,
                displayName: 'Withheld Taxes',
                width: '120',
                cellClass: 'c-dataType-number',
                resizable: true,
                enableColumnMoving: false
            }] : []),
            {
                field: 'totalCharge',
                displayName: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.TOTAL_CHARGE),
                cellTemplate: require('./../../components/billingPayments/cellTemplates/total.charge.template.html'),
                headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE,
                width: '132',
                cellClass: 'c-dataType-number',
                minWidth: 70,
                resizable: true,
                enableSorting: true,
                enableColumnMoving: false
            },
            {
                field: 'moreAction',
                displayName: '',
                cellTemplate: require('./../../components/billingPayments/cellTemplates/more.actions.template.html'),
                width: '*',
                maxWidth: 70,
                enableColumnMoving: false
            }
        ]
    };
    const $filter = InjectorHelper.getFilterService();

    const formatView = (view) => {
        if (typeof view === 'undefined') {
            return {};
        } else {
            const isCreditType = invoiceSummary?.CurrentDue < 0;
            const currency = view.CurrencyCode || invoiceSummary?.CurrencyCode || currentCustomer.SubscriberCurrency;
            const discountAmount = getDiscountAmount(view);
            const discountDetails = getDiscountsDetails(currency, view.Discounts || [], discountsMetadataMap);

            const moreOptions = [];
            const showApplyAdjustment = (view.ItemType === INVOICE_ITEM_TYPES.TAX_ITEM || view.ItemType === INVOICE_ITEM_TYPES.USAGE_SUMMARY
                || view.ItemType === INVOICE_ITEM_TYPES.BILLING_FEES || view.ItemType === INVOICE_ITEM_TYPES.LATE_PAYMENT_FEE) ? false : canApplyAdjustment;

            if (showApplyAdjustment && hasAccess(userSecurityAttributes, PAYMENT_ADJUSTMENT_ACCESS) && !isCreditType) {
                moreOptions.push({
                    id: 'APPLY_ITEM_ADJUSTMENT',
                    title: i18n.translate(LocaleKeys.ADJUSTMENTS.APPLY_ITEM_ADJUSTMENT)
                });
            }

            if (features[FeatureToggleConstants.IS_LINE_ITEM_DISPUTE_AVAILABLE] && !isCreditType) {
                if (view.CaseId?.Value) {
                    moreOptions.push({
                        id: 'VIEW_CASE_DETAILS',
                        title: i18n.translate(LocaleKeys.CASE.VIEW_CASE_DETAILS)
                    });
                } else {
                    if (showApplyAdjustment && !invoiceSummary?.IsStatement && hasAccess(userSecurityAttributes, DISPUTE_INVOICE) && !invoiceSummary?.CaseId?.Value) {
                        moreOptions.push({
                            id: 'DISPUTE_LINE_ITEM',
                            title: i18n.translate(LocaleKeys.DISPUTE_LINE_ITEM)
                        });
                    }
                }
            }

            const invoiceItemTypeName = invoiceItemTypes.find(item => {
                return +item.Value === view.ItemType;
            })?.Name;

            return {
                accountNumber: view.AccountNumber,
                applyAdjustment: '',
                canApplyAdjustment: (view.ItemType === INVOICE_ITEM_TYPES.TAX_ITEM || view.ItemType === INVOICE_ITEM_TYPES.USAGE_SUMMARY
                    || view.ItemType === INVOICE_ITEM_TYPES.BILLING_FEES) ? false : canApplyAdjustment,
                caseId: view.CaseId?.Value,
                chargePeriod: formatChargeDate(view.PeriodStartDate, view.PeriodEndDate, view),
                currencyCode : currency,
                date: view.Date || null,
                discount: (discountAmount || discountDetails.length) ? $filter('invCurrency')(discountAmount, currency, false, true) : null,
                discounts: discountDetails,
                discountTemplate: require('./../../components/billingPayments/cellTemplates/discount.tooltip.template.html'),
                discountToolTipText: discountDetails.length,
                discountNegative: (discountAmount || discountDetails.length) ? discountAmount <= 0 : false,
                invoiceId : view.InvoiceId || null,
                itemId: view.EventId,
                invoiceItemId: view.InvoiceItemId,
                invoiceItemBalance: view.Amount,
                moreOptions,
                originalCharge: view.Amount ? $filter('invCurrency')(view.Amount, currency, false, true) : null,
                originalChargeNegative: view.Amount < 0,
                prorate: view.Prorate,
                idTag: i18n.translate(LocaleKeys.IDENTIFIER_ABBREVIATION),
                referenceId: view.ReferenceId || null,
                subscriberItem: view.Name || null,
                serviceName: (view.ServiceIdentifier && view.ServiceIdentifier.Value) ? getFriendlyLedgerName(view.ServiceIdentifier) : null,
                serviceType: invoiceItemTypeName || null,
                // TODO - Domain team needs to remove this and all front end math
                totalChargeAmount: view.Amount ? view.Amount + discountAmount : null,
                totalCharge: formatTotalCharge(view.Amount, discountAmount, currency, $filter),
                ...(witheldTaxesApplicable && {
                    withheldTaxes: view.WithheldTaxes.length > 0 ? $filter('invCurrency')(view.WithheldTaxes[0].TaxAmount, currency, false, true) : null
                })
            };
        }
    };
    tableData.data = map(formatView, invoiceItems);

    return tableData;
}

const getDiscountAmount = (view) => {
    return (view && view.TotalDiscountAmount) ? view.TotalDiscountAmount : 0;
};

const getDiscountsDetails = (currency, discounts=[], discountsMetadataMap={}) => {
    let filteredDiscountDetails = [];
    const discountDetails = discounts.map(discount => {
        const discountName = discountsMetadataMap[discount.Id] ? (discountsMetadataMap[discount.Id].StorefrontText || discountsMetadataMap[discount.Id].Name) : discount.Name;
        return {
            Name: discountName,
            Amount: discount.Amount,
            Currency: currency,
            IsProrate: discount.Prorate,
            PeriodStartDate: discount.PeriodStartDate,
            PeriodEndDate: discount.PeriodEndDate
        };
    });

    filteredDiscountDetails = discountDetails.filter(discount => {
        return discount;
    });
    return filteredDiscountDetails;
};


const formatTotalCharge = (amount, discountAmount, currencyCode, $filter) => {
    if (typeof amount === 'number') {
        return $filter('invCurrency')((amount + discountAmount), currencyCode, false, true);
    }
    return null;
};

const getFriendlyLedgerName = (serviceIdentifier) => {
    const friendlyName = pathOr('', ['FriendlyName'], serviceIdentifier);
    if (friendlyName && friendlyName !== NA) {
        return friendlyName;
    } else if (friendlyName === NA) {
        return null;
    } else {
        serviceIdentifier.Value || serviceIdentifier.FormattedValue;
    }
    return (friendlyName && friendlyName !== NA) ? friendlyName : (friendlyName === NA) ? null : serviceIdentifier.Value;
};
export const getFriendlyName = (serviceIdentifier) => {
    if (serviceIdentifier) {
        return serviceIdentifier.FriendlyName ? serviceIdentifier.FriendlyName : serviceIdentifier.Value;
    }
};
