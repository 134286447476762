import find from 'ramda/src/find';
import map from 'ramda/src/map';
import propEq from 'ramda/src/propEq';
import LocaleKeys from '../../locales/keys';
import {i18n} from 'invision-core';
import {getFilterService} from 'invision-core/src/components/injectables/injector.helper';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {RIGHT_ALIGNED_HEADER_TEMPLATE} from 'invision-ui/lib/components/collections/datatable/cellTemplates/cellTemplates';
import {
    ACCOUNT_SUMMARY_ITEM_LEVEL_MENU_OPTIONS,
    BILL_ADJUSTMENT,
    CONVERT_TO_CREDIT,
    VIEW_RECEIVABLES
} from '../../components/billingPayments/billing.payments.constants';

import {
    ACCOUNTING_METHODS_NAMES,
    BILLING_PAYMENT_TYPE_CODES,
    CREDIT_NOTE_TYPE_CODE,
    INVOICE_TYPE,
    MISC_ADJUSTMENT_TYPE_CODE,
    PAYMENT_TYPE_CODES
} from '../constants/billing.payments.constants';

const getTableDataHeaders = (showFailedColumn) => {
    const headers = [{
        field: 'date',
        displayName: i18n.translate(LocaleKeys.DATE),
        cellTemplate: require('./../../components/billingPayments/cellTemplates/date.template.html'),
        width: 170,
        minWidth: 90,
        sort: {
            direction: LocaleKeys.DESCENDING,
            priority: 0
        },
        resizable: true,
        enableSorting: false,
        enableColumnMoving: false
    },
    {
        field: 'type',
        displayName: i18n.translate(LocaleKeys.TYPE),
        cellTemplate: require('./../../components/billingPayments/cellTemplates/type.template.html'),
        width: 140,
        minWidth: 70,
        resizable: true,
        enableSorting: false,
        enableColumnMoving: false
    },
    {
        field: 'name',
        displayName: i18n.translate(LocaleKeys.NAME),
        cellTemplate: require('./../../components/billingPayments/cellTemplates/adjustments.template.html'),
        width: '*',
        minWidth: 100,
        resizable: true,
        enableSorting: false,
        enableColumnMoving: false
    }, {
        field: 'charge',
        displayName: i18n.translate(LocaleKeys.CHARGE),
        cellTemplate: require('./../../components/billingPayments/cellTemplates/charge.template.html'),
        headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE,
        width: 150,
        minWidth: 70,
        cellClass: 'c-dataType-number',
        resizable: true,
        enableSorting: false,
        enableColumnMoving: false
    }, {
        field: 'payment',
        displayName: i18n.translate(LocaleKeys.PAYMENT),
        headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE,
        width: 140,
        minWidth: 70,
        cellClass: 'c-dataType-number',
        cellTemplate: require('./../../components/billingPayments/cellTemplates/payment.template.html'),
        resizable: true,
        enableSorting: false,
        enableColumnMoving: false
    }, {
        field: 'balance',
        displayName: i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.ACCOUNT_BALANCE),
        cellTemplate: require('./../../components/billingPayments/cellTemplates/balance.template.html'),
        headerCellTemplate: require('./../../components/billingPayments/cellTemplates/balance.header.cell.template.html'),
        width: 150,
        minWidth: 70,
        cellClass: 'c-dataType-number',
        resizable: true,
        enableSorting: false,
        enableColumnMoving: false
    },
    {
        field: 'moreOptionsMenu',
        displayName: '',
        cellTemplate: require('./../../components/billingPayments/cellTemplates/accounts.more.options.menu.items.template.html'),
        width: '*',
        maxWidth: 70,
        enableColumnMoving: false
    }];
    if (showFailedColumn) {
        headers.splice(2, 0, {
            field: 'failedPayment',
            displayName: '',
            cellTemplate: require('../../components/billingPayments/cellTemplates/failed.payment.template.html'),
            width: 90,
            minWidth: 90,
            maxWidth: 90,
            resizable: false,
            enableSorting: false,
            enableColumnMoving: false
        });
    }
    return  headers;
};

export function getAdjustmentCodeNames(value, Codes) {
    let val = '';
    Codes.forEach((code) => {
        if (code.Value === value) {
            val = code.Name;
        }
    });
    return val ? val : value;
}

function mapTypeToString(type, isPaymentRefund, isStatement) {

    if (isPaymentRefund) {
        return i18n.translate(LocaleKeys.PAYMENT_REFUND);
    } else if (isStatement) {
        return i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.STATEMENT);
    }

    switch (type) {
        case BILLING_PAYMENT_TYPE_CODES.ADJUSTMENT:
            return i18n.translate(LocaleKeys.ADJUSTMENT);
        case BILLING_PAYMENT_TYPE_CODES.PAYMENT:
            return i18n.translate(LocaleKeys.PAYMENT);
        case BILLING_PAYMENT_TYPE_CODES.INVOICE_SUMMARY:
            return i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.INVOICE_SUMMARY);
        case BILLING_PAYMENT_TYPE_CODES.CREDIT_NOTE:
            return i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_TYPES.CREDIT_NOTE);
        case BILLING_PAYMENT_TYPE_CODES.DEBIT_NOTE:
            return i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_TYPES.DEBIT_NOTE);
        default:
            return null;
    }
}

function getAdjustmentTypeName(adjustmentType, allTypes) {
    const typeItems = allTypes ? allTypes.items : [];
    const typeObj = typeItems.find((item) => {
        return item.Value === adjustmentType;
    });
    return typeObj ? typeObj.Name : null;
}

function getChargeByType(type, amount, currencyCode) {
    const $filter = getFilterService();
    return (type === BILLING_PAYMENT_TYPE_CODES.INVOICE_SUMMARY) ||
            (type === BILLING_PAYMENT_TYPE_CODES.ADJUSTMENT) ||
            (type === BILLING_PAYMENT_TYPE_CODES.CREDIT_NOTE) ||
            (type === BILLING_PAYMENT_TYPE_CODES.DEBIT_NOTE) ?
        $filter('invCurrency')(amount, currencyCode, null, false, false) : null;
}

function getPaymentByType(type, amount, currencyCode) {
    const $filter = getFilterService();
    return ((type === BILLING_PAYMENT_TYPE_CODES.PAYMENT)) ? $filter('invCurrency')(amount, currencyCode) : null;
}

export function createTableViewModel(views, adjustmentTypes, adjustmentReasons, paymentInstrumentTypes, posPaymentTypes = [], creditToPaymentMapping, currentCustomerAccountingMethod) {
    adjustmentReasons = (adjustmentReasons) ? adjustmentReasons.items : [];
    const showFailedColumn = views.some((view) => {
        return view.FailedPayment;
    });

    const tableData = {
        columnDefs: getTableDataHeaders(showFailedColumn)
    };
    const $filter = getFilterService();
    const formatView = (view) => {
        if (!view) {
            return {};
        } else {
            let sref, piTypeName, piSubType, isPaymentRefund;
            const runningBalance = view.Type === BILLING_PAYMENT_TYPE_CODES.INVOICE_SUMMARY ? view.OutstandingBalance : view.RunningBalance;
            const paymentId = i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.PAYMENT_ID);

            switch (view.Type) {
                case BILLING_PAYMENT_TYPE_CODES.ADJUSTMENT:
                    sref = 'index.customercare.customer.billingpayments.creditNote';
                    break;
                case BILLING_PAYMENT_TYPE_CODES.INVOICE_SUMMARY:
                    sref = 'index.customercare.customer.billingpayments.detail';
                    break;
                case BILLING_PAYMENT_TYPE_CODES.CREDIT_NOTE:
                    sref = 'index.customercare.customer.billingpayments.creditNote';
                    break;
                case BILLING_PAYMENT_TYPE_CODES.DEBIT_NOTE:
                    sref = 'index.customercare.customer.billingpayments.creditNote';
                    break;
                case BILLING_PAYMENT_TYPE_CODES.PAYMENT:
                    sref = '';
                    piTypeName = view.Name ? `${paymentId} - ${view.ReferenceId} | ${view.Name}` : `${paymentId} - ${view.ReferenceId}`;
                    if (view.PaymentTypeCode === PAYMENT_TYPE_CODES.POINT_OF_SALE) {
                        const piType = paymentInstrumentTypes ? find(propEq(String(view.PaymentTypeCode), 'Value'))(paymentInstrumentTypes) : null;
                        piSubType = posPaymentTypes.length ? find(propEq(String(view.PaymentSubTypeCode), 'Value'))(posPaymentTypes) : null;
                        if (piSubType) {
                            if (piType) {
                                piTypeName = `${piType.Name} - ${piSubType.Name}`;
                            } else {
                                piTypeName = `${view.Name} - ${piSubType.Name}`;
                            }
                        }
                    }
                    break;
                default:
                    sref = '';
                    break;
            }

            const moreOptionsMenuItems = [];
            if (currentCustomerAccountingMethod === ACCOUNTING_METHODS_NAMES.OPEN_INVOICE && view.Type === BILLING_PAYMENT_TYPE_CODES.CREDIT_NOTE && view.RemainingBalance !== 0) {
                moreOptionsMenuItems.push({
                    title: i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.APPLY_CREDIT_NOTE),
                    id: ACCOUNT_SUMMARY_ITEM_LEVEL_MENU_OPTIONS.APPLY_CREDIT_NOTE
                });
            }
            if (view.TransactionId || view.PaymentTransactionId) {
                moreOptionsMenuItems.push({
                    title: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.VIEW_TRANSACTION_DETAILS),
                    id: ACCOUNT_SUMMARY_ITEM_LEVEL_MENU_OPTIONS.VIEW_TRANSACTION_DETAILS
                });
            }
            if ((view.Receivables || []).length) {
                moreOptionsMenuItems.push({
                    title: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.VIEW_RECEIVABLES),
                    id: ACCOUNT_SUMMARY_ITEM_LEVEL_MENU_OPTIONS.VIEW_RECEIVABLES
                });
            }

            return {
                adjustmentCode: (view.AdjustmentReasonCode) ? getAdjustmentCodeNames(view.AdjustmentReasonCode, adjustmentReasons) : null,
                adjustmentId: view.Type === BILLING_PAYMENT_TYPE_CODES.ADJUSTMENT ? view.Id : null,
                adjustmentType: getAdjustmentTypeName(view.AdjustmentTypeCode, adjustmentTypes),
                adjustmentUser: view.UserName || null,
                allocationDetails: view.AllocDetails || null,
                amount: (view.Type === BILLING_PAYMENT_TYPE_CODES.INVOICE_SUMMARY ? view.Balance : view.Amount) || null,
                balance: (runningBalance && view.CurrencyCode) ? $filter('invCurrency')(runningBalance, view.CurrencyCode) : $filter('invCurrency')(runningBalance, 'USD'),
                canApplyCreditNote: currentCustomerAccountingMethod === ACCOUNTING_METHODS_NAMES.OPEN_INVOICE && view.Type === BILLING_PAYMENT_TYPE_CODES.CREDIT_NOTE && view.RemainingBalance !== 0,
                charge: (view.Type === BILLING_PAYMENT_TYPE_CODES.INVOICE_SUMMARY ? getChargeByType(view.Type, view.Balance, view.CurrencyCode) : getChargeByType(view.Type, view.Amount, view.CurrencyCode)) || null,
                creditInvoiceNumber: view.CreditInvoiceNumber,
                creditNoteId: view.Type === BILLING_PAYMENT_TYPE_CODES.CREDIT_NOTE ? view.Id : null,
                creditNoteNumber: view.CreditNoteNumber || null,
                currencyCode: view.CurrencyCode,
                date: view.Date || view.IssueDate || null,
                debitNoteId: view.Type === BILLING_PAYMENT_TYPE_CODES.DEBIT_NOTE ? view.Id : null,
                description: view.Description || null,
                failedPayment: view.FailedPayment,
                hasOpenDispute: !!(view.CaseId?.Value || view.HasOpenLineItemDispute),
                invoiceId: view.AllocDetails?.InvoiceId || view.InvoiceId || null, // if the ledger summary is of Payment type we use invoiceId, invoiceNumber and allocationDate from AllocationDetails Property.
                invoiceNumber: view.AllocDetails?.InvoiceNumber || view.InvoiceNumber,
                isCreditNoteOrAdjustment: convertStringToNumber(view.AdjustmentTypeCode) === CREDIT_NOTE_TYPE_CODE || (convertStringToNumber(view.AdjustmentTypeCode) === MISC_ADJUSTMENT_TYPE_CODE && view.Amount < 0),
                isNegative: runningBalance < 0,
                isPaymentRefund: view.Refund,
                more: '',
                moreOptionsMenuItems,
                name: piTypeName ? piTypeName : view.Type === BILLING_PAYMENT_TYPE_CODES.INVOICE_SUMMARY ? view.InvoiceNumber : view.Name || null,
                originalPayment: getPaymentByType(view.Type, view.OriginalPaymentAmount, view.CurrencyCode),
                payment: getPaymentByType(view.Type, view.Amount, view.CurrencyCode),
                paymentIdOfCreditNote: creditToPaymentMapping[view.ReferenceId],
                paymentMode: view.Type === BILLING_PAYMENT_TYPE_CODES.PAYMENT ? view.Name : null,
                paymentReferenceId: (view.Type === BILLING_PAYMENT_TYPE_CODES.CREDIT_NOTE && view.PaymentReferenceId) ? `| ${paymentId} - ${view.PaymentReferenceId}` : view.PaymentReferenceId || null,
                paymentTransactionId: view.PaymentTransactionId || null,
                receivables: view.Receivables || [],
                referenceId: view.ReferenceId || null,
                remainingBalance: view.Type === BILLING_PAYMENT_TYPE_CODES.CREDIT_NOTE ? view.RemainingBalance : null,
                split: (view.Type === BILLING_PAYMENT_TYPE_CODES.ADJUSTMENT || view.Type === BILLING_PAYMENT_TYPE_CODES.PAYMENT) ? view.Split : undefined,
                sref: sref,
                taxCertificateDate: (view.Type === BILLING_PAYMENT_TYPE_CODES.CREDIT_NOTE && view.TaxCertificateDate) ? view.TaxCertificateDate : null,
                taxCertificateId: (BILLING_PAYMENT_TYPE_CODES.CREDIT_NOTE && view.TaxCertificateId) ? view.TaxCertificateId : null,
                transactionId: view.TransactionId || null,
                type: view.Refund ? i18n.translate(LocaleKeys.REFUND) : mapTypeToString(view.Type, view.Refund, view.IsStatement)
            };
        }
    };
    tableData.data = map(formatView, views);
    return tableData;
}

export function formatOpenInvoicesList(invoices) {
    const $filter = getFilterService();
    return invoices.map((invoice) => {

        const moreOptionsMenuItems = [];
        if (invoice.Invoice.Balance < 0) {
            moreOptionsMenuItems.push({
                title: i18n.translate(LocaleKeys.CONVERT_TO_CREDIT),
                id: CONVERT_TO_CREDIT
            });
        } else {
            moreOptionsMenuItems.push({
                title: i18n.translate(LocaleKeys.ADJUSTMENTS.APPLY_MISC_ADJUSTMENT),
                id: BILL_ADJUSTMENT
            });
        }
        if ((invoice.Invoice.Receivables || []).length) {
            moreOptionsMenuItems.push({
                title: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.VIEW_RECEIVABLES),
                id: VIEW_RECEIVABLES
            });
        }

        return {
            accountNumber: invoice.Invoice.AccountNumber,
            adjustment: invoice.Invoice.TotalAdjustmentAmount ? $filter('invCurrency')(invoice.Invoice.TotalAdjustmentAmount, invoice.Invoice.CurrencyCode) : null,
            charge: $filter('invCurrency')(invoice.Invoice.Balance, invoice.Invoice.CurrencyCode),
            currencyCode: invoice.Invoice.CurrencyCode,
            currentDue: invoice.Invoice.CurrentDue,
            dateCreated: $filter('localShort')(invoice.Invoice.IssueDate),
            dueDate: $filter('localShort')(invoice.Invoice.DueDate),
            hasOpenDispute: !!(invoice.CaseId?.Value || invoice.HasOpenLineItemDispute),
            invoiceBalance: invoice.Invoice.CurrentDue ? $filter('invCurrency')(invoice.Invoice.CurrentDue, invoice.Invoice.CurrencyCode) : null,
            invoiceId: invoice.Invoice.InvoiceId,
            invoiceNumber: invoice.Invoice.InvoiceNumber,
            invoiceType: invoice.Invoice.InvoiceType === INVOICE_TYPE.PERIODIC ? i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.PERIODIC) : i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.ON_DEMAND),
            isAdjustmentNegative: invoice.Invoice.TotalAdjustmentAmount < 0,
            isChargeNegative: invoice.Invoice.Balance < 0,
            isInvoiceBalanceNegative: invoice.Invoice.CurrentDue < 0,
            isOutstandingBalanceNegative: invoice.Invoice.OutstandingBalance < 0,
            isPreviousInvoiceBalanceNegative: invoice.Invoice.PreviousInvoiceBalance < 0,
            outstandingBalance: invoice.Invoice.OutstandingBalance ? $filter('invCurrency')(invoice.Invoice.OutstandingBalance, invoice.Invoice.CurrencyCode) : null,
            payment: invoice.Invoice.TotalPaymentAmount ? $filter('invCurrency')(invoice.Invoice.TotalPaymentAmount, invoice.Invoice.CurrencyCode) : null,
            previousInvoiceBalance: invoice.Invoice.PreviousInvoiceBalance ? $filter('invCurrency')(invoice.Invoice.PreviousInvoiceBalance, invoice.Invoice.CurrencyCode) : null,
            receivables: invoice.Invoice.Receivables || [],
            totalOnDemandInvoices: invoice.Invoice.TotalOnDemandInvoices ? $filter('invCurrency')(invoice.Invoice.TotalOnDemandInvoices, invoice.Invoice.CurrencyCode) : null,
            type: invoice.Type,
            moreOptionsMenuItems: moreOptionsMenuItems
        };
    });
}
